import { PaymentType } from "../models/Payment";
import store from "../redux/store";

export const universalFee = (clientMsbId: string, departmentMsbId: string, paymentChannelMsbId: string, amount: number) => {
    let baseApiUrl = store.getState().webAppSettings.baseApiUrl;
    let bearerToken = store.getState().webAppSettings.bearerToken;
    let url = `${baseApiUrl}/MSB_Order/api/v1/ProcessOrder/GetFeesForAmount`

    return fetch(url, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${bearerToken}`,
            "ClientId": `${clientMsbId}`,
            "DepartmentId": `${departmentMsbId}`,
            "PaymentChannelId": `${paymentChannelMsbId}`
        },
        body: JSON.stringify( { "paymentType": PaymentType.CreditCard.toString(), "isCardPresent": true, "amount": `${amount}` })
    })
    .then(response => {
        if (response.ok) return response.json();
        throw new Error(response.status.toString());
    })
    .then(fees => {
        console.log("Universal Fee OK");
        const uniform = fees
            .filter((f: any) => f.cardType !== "ElectronicCheck")
            .map((f: any) => ({ ...f, totalAmount: parseFloat(f.totalAmount).toFixed(2) }));
        if (uniform.length > 0 && uniform.every((f: any) => f.convenienceFee === uniform[0].convenienceFee)) {
            return uniform[0].convenienceFee;
        }
        return 0.00;
    })
    .catch((error) => {
        console.log("Universal Fee ERROR", error);
        return 0.00;
    });
};

