import React, { useState, useEffect, useMemo } from "react";
import { Form, Modal, Button, Row, Col, ButtonToolbar, Badge } from 'react-bootstrap';
import { clearSelectedDepartmentPaymentChannels, getClientBankingAction, getPaymentChannelsByUserType, getTransactionsAction } from '../../../redux/actions/payments/paymentTransactions';
import { SearchList, TransactionTypeEnum } from '../../../models/Payment';
import Select from 'react-select';
import { IActionResult, IAppState } from '../../../redux/storeTypes';
// @ts-ignore
import FormHeader from '../../clients/components/forms/FormHeader';
import { connect, useDispatch } from "react-redux";
import moment from 'moment';
import CurrencyInput from "../../../components/currency/CurrencyInput";
import { ReportType } from "../../../models/Reports";
import { Client, ClientName, DepartmentName } from "../../../models/Client";
import { DELETE_CLIENT_BANKING_SUCCESS, getClientsAction, SAVE_CLIENT_BANKING_SUCCESS } from "../../../redux/actions/clients/clients";
import { User, UserTypeEnum } from "../../../models/User";
import { sendErrorToastAction } from "../../../redux/actions/toast";
import _ from 'lodash';
import { getTableConfigurationAction } from "../../../redux/actions/reports/report";
import PerfectScrollbar from 'react-perfect-scrollbar';
import { CurrencyAmountFormatter } from "../../../components/Formatters";
import Switch from "react-switch";
import DepartmentPaymentChannelSelector from "./DepartmentPaymentChannelSelector";

interface IAdvancedSearch {
    formSchema: any;
    transactionType: any;
    reportType: ReportType;
    isFetching: boolean;
    searchReady: boolean;
    currentUser: User;
    clients: Array<Client>;
    departmentNames: Array<DepartmentName>;
    actionResult: IActionResult;
    page?: number;
    pageSize?: number;
    sortBy?: string;
    sortDirection?: string;
    totalRecords?: number;
    searchFields?: any;
    paymentChannelsByUser?: any;
    clientNames: any;
}

const AdvancedSearch = ({ formSchema, transactionType, reportType, searchReady, isFetching, currentUser, clients, actionResult, page, pageSize, sortBy, sortDirection, searchFields, paymentChannelsByUser, clientNames }: IAdvancedSearch) => {
    const [showModal, setShowModal] = useState(false);
    const actionToken = "AdvancedSearch";
    const dispatch = useDispatch();

    const defaultStartDate = moment()?.subtract(1, "day").format('YYYY-MM-DD');
    const defaultEndDate = moment()?.format('YYYY-MM-DD');

    const [searchList, setSearchList] = useState<SearchList>(Object.assign(new SearchList(), { startDate: defaultStartDate, endDate: defaultEndDate }));
    const [trigger, setTrigger] = useState(false);
    const [clientList, setClientList] = useState<any>([]);
    const [executeDynamicQuery, setExecuteDynamicQuery] = useState<boolean>(false);
    const [badges, setBadges] = useState();
    const timeZone = moment.tz.guess();

    const search = () => {
        if (reportType != ReportType.ClientBanking) {
            let _searchList = new SearchList();

            Object.entries(searchList).map(([key, value]) => {
                if (!["startDate", "endDate", "startTime", "endTime", "startAmount", "endAmount", "subTotalStartAmount", "subTotalEndAmount", "customSelectComponent", "departmentIds", "paymentChannelIds"].some(k => key === k)) {
                    (_searchList[key as keyof SearchList] as keyof typeof key) = value as keyof typeof key;
                }
            })

            if (searchList.startAmount || searchList.endAmount) {
                _searchList.totalAmount = ((searchList.startAmount) ? `${searchList.startAmount}` : "0.00") + ((searchList.endAmount) ? `<->${searchList.endAmount}` : "<->9999999.99");
            }

            if (searchList.subTotalStartAmount || searchList.subTotalEndAmount) {
                _searchList.amount = ((searchList.subTotalStartAmount) ? `${searchList.subTotalStartAmount}` : "0.00") + ((searchList.subTotalEndAmount) ? `<->${searchList.subTotalEndAmount}` : "<->9999999.99");
            }

            if (searchList.customSelectComponent && searchList.customSelectComponent?.length > 0) {
                _searchList.transactionType = searchList.customSelectComponent.map(({ value }) => `${TransactionTypeEnum[value]}`).join("|");
                if (_searchList.transactionType.includes(TransactionTypeEnum["Void"].toString())) _searchList.transactionType += "|" + TransactionTypeEnum["Reversal"];
                if (_searchList.transactionType.includes(TransactionTypeEnum["Refund"].toString())) _searchList.transactionType += "|" + TransactionTypeEnum["Return"];
            }

            if (searchList.paymentTypeSelect && searchList.paymentTypeSelect?.length > 0) {
                _searchList.paymentType = searchList.paymentTypeSelect.map(({ value }) => `${value}`).join("|");
            }

            if (searchList.departmentIds && searchList.departmentIds?.length > 0) {
                _searchList.departmentId = searchList.departmentIds.map((id) => `${id}`).join("|");
            }

            if (searchList.paymentChannelIds && searchList.paymentChannelIds?.length > 0) {
                _searchList.paymentChannelId = searchList.paymentChannelIds.map((id) => `${id}`).join("|");
            }

            if (moment(searchList.startDate, 'YYYY-MM-DD', true)?.isValid() && moment(searchList.endDate, 'YYYY-MM-DD', true)?.isValid()) {
                _searchList.createdAt = `${moment(searchList.startDate + ((searchList.startTime) ? "T" + searchList.startTime + ":00.000" : "T00:00:00.000")).utc().format('YYYY-MM-DDTHH:mm:ss.SSS')}<->${moment(searchList.endDate + ((searchList.endTime) ? "T" + searchList.endTime + ":59.999" : "T23:59:59.999")).utc().format('YYYY-MM-DDTHH:mm:ss.SSS')}`;
            }
            if (executeDynamicQuery) {
                delete _searchList.paymentTypeSelect;
                dispatch(getTransactionsAction(1, _searchList, actionToken, currentUser, transactionType, reportType, paymentChannelsByUser));
            }
        }
        if (reportType === ReportType.ClientBanking) {
            let _searchList = new SearchList();
            Object.entries(searchList).map(([key, value]) => {
                if (!["startDate", "endDate", "departmentIds"].some(k => key === k)) {
                    (_searchList[key as keyof SearchList] as keyof typeof key) = value as keyof typeof key;
                }
            })

            if (searchList.departmentIds && searchList.departmentIds?.length > 0) {
                _searchList.departmentId = searchList.departmentIds.map((id) => `${id}`).join(",");
            }

            const clientBankingSearchValues = _.pickBy(_searchList, value => value)
            dispatch(getClientBankingAction(page!, pageSize!, clientBankingSearchValues, actionToken, sortBy, sortDirection));
        }

        dispatch(getTableConfigurationAction(currentUser?.msbId, reportType, 'userPreferences'));
    }

    useEffect(() => {
        if (!clients) {
            dispatch(getClientsAction(1, 1000));
        }
        const clientDropdown = clients?.filter(clientDetails => currentUser?.userClients.some(userClient => clientDetails.msbId === userClient.clientMSBId));
        setClientList(clientDropdown);
    }, [clients]);

    useEffect(() => {
        setBadges(searchFields);
    }, [searchFields]);

    useEffect(() => {
        search();
    }, [trigger, page, pageSize, sortBy, sortDirection, executeDynamicQuery]);

    useEffect(() => {
        if (actionResult && actionResult.result) {
            if (actionResult.result === SAVE_CLIENT_BANKING_SUCCESS || actionResult.result === DELETE_CLIENT_BANKING_SUCCESS) {
                setTrigger(!trigger);
            }
        }
    }, [actionResult]);


    useEffect(() => {
        if (reportType) {
            setSearchList(Object.assign(new SearchList(), { startDate: defaultStartDate, endDate: defaultEndDate, clientIds: '' }));
        }
    }, [reportType]);

    useEffect(() => {
        if (currentUser) {
            const filterIds = {
                filterIds: currentUser.userTypeEnum === UserTypeEnum.Navient
                    ? _.map(currentUser.userClients, 'clientMSBId')
                    : currentUser.userTypeEnum === UserTypeEnum.Client
                        ? _.map(currentUser.userDepartments, 'departmentMSBId')
                        : []
            };
            dispatch(getPaymentChannelsByUserType(currentUser.userTypeEnum === UserTypeEnum.Navient, filterIds, 'advance search'));
        }
    }, [currentUser]);

    const handleSubmit = (event: any) => {
        event.preventDefault();
        if (moment(searchList.startDate + ((searchList.startTime) ? "T" + searchList.startTime + ":00.000" : "T00:00:00.000")).utc() > moment(searchList.endDate + ((searchList.endTime) ? "T" + searchList.endTime + ":59.999" : "T23:59:59.999")).utc()) {
            dispatch(sendErrorToastAction(`Start date/time must be less than end date/time.`));
            return;
        }
        const isValidNumber = (value: any) => typeof value === 'number' ? !Number.isNaN(value) : typeof value === 'string' ? value !== '' && !isNaN(Number(value)) : false;
        if (
            isValidNumber(searchList.startAmount) &&
            isValidNumber(searchList.endAmount) &&
            Number(searchList.startAmount) > Number(searchList.endAmount)
        ) {
            dispatch(sendErrorToastAction("Start amount must be less than end amount."));
            return;
        }

        if (
            isValidNumber(searchList.subTotalStartAmount) &&
            isValidNumber(searchList.subTotalEndAmount) &&
            Number(searchList.subTotalStartAmount) > Number(searchList.subTotalEndAmount)
        ) {
            dispatch(sendErrorToastAction("Start amount for subtotal must be less than end amount of subtotal."));
            return;
        }
        
        if (showModal) setTimeout(() => setShowModal(false), 100);
        setTrigger(!trigger);
        setExecuteDynamicQuery(true);
    };

    const clearForms = (event: any) => {
        event.preventDefault();
        dispatch(clearSelectedDepartmentPaymentChannels())
        setSearchList(Object.assign(new SearchList(), { startDate: defaultStartDate, endDate: defaultEndDate, clientIds: '' }));
        setTrigger(!trigger);
    }

    const updateSelection = (data: { departmentIds: string[], paymentChannelIds: string[] }) => {
        setSearchList(prevState => ({
            ...prevState,
            departmentIds: data.departmentIds,
            paymentChannelIds: data.paymentChannelIds
        }));
    };

    const onHideModal = () => {
        setTimeout(() => setShowModal(false), 100);
    }

    const getFormElement = (elementName: any, elementSchema: any) => {
        const props = {
            name: elementName,
            label: elementSchema.label,
            options: elementSchema.options,
            maxLength: elementSchema.maxLength || undefined
        };

        if (elementSchema.type === "select") {
            return (
                <Form.Group controlId={props.name}>
                    <Form.Label>{props.label}</Form.Label>
                    <Form.Control as="select" value={searchList[elementName as keyof SearchList]} onChange={(e: any) => setSearchList({ ...searchList, [elementName as keyof SearchList]: e.target.value })}>
                        <option key={0} value="" >Select...</option>
                        {props.options.map((option: any, index: any) => <option key={index} value={option.value} label={option.label || option.value} />)}
                    </Form.Control>
                </Form.Group>
            )
        }
        else if (elementSchema.type === "currency") {
            return (
                <Form.Group controlId={props.name}>
                    <Form.Label>{props.label}</Form.Label>
                    <CurrencyInput
                        className="form-control"
                        placeholder="$ 0.00"
                        maxLength={10}
                        decimalsLimit={2}
                        prefix="$ "
                        value={searchList[elementName as keyof SearchList]}
                        onValueChange={(value, name) => setSearchList({ ...searchList, [elementName as keyof SearchList]: value })}
                    />
                </Form.Group>
            )
        }
        else if (elementName === 'customSelectComponent') {
            return (
                <Form.Group controlId={props.name}>
                    <Form.Label>{props.label}</Form.Label>
                    <Select
                        isMulti
                        closeMenuOnSelect={false}
                        value={searchList.customSelectComponent}
                        onChange={(selected: any) => setSearchList({ ...searchList, customSelectComponent: selected })}
                        options={props.options}
                        className="react-select-container"
                        classNamePrefix="react-select"
                    />
                </Form.Group>
            )
        }
        else if (elementName === 'paymentTypeSelect') {
            return (
                <Form.Group controlId={props.name}>
                    <Form.Label>{props.label}</Form.Label>
                    <Select
                        isMulti
                        closeMenuOnSelect={false}
                        value={searchList.paymentTypeSelect}
                        onChange={(selected: any) => setSearchList({ ...searchList, paymentTypeSelect: selected })}
                        options={props.options}
                        className="react-select-container"
                        classNamePrefix="react-select"
                    />
                </Form.Group>
            )
        }
        else {
            return (
                <Form.Group controlId={props.name}>
                    <Form.Label>{props.label} {
                        props.name === 'itemReferenceNumber' &&
                        <div style={{ display: 'flex', alignItems: 'center', marginLeft: 'auto', fontStyle: 'italic' }}>
                            <Switch
                                onChange={(selected: any) => setSearchList({ ...searchList, itemReferenceToggle: selected })}
                                checked={searchList?.itemReferenceToggle}
                                onColor={'#0057B6'}
                                offColor={'#BEBEBE'}
                                handleDiameter={12}
                                uncheckedIcon={false}
                                checkedIcon={false}
                                height={16}
                                width={28}
                                activeBoxShadow={'0 0 0 1px #0057B6'}
                            />
                            <span style={{ marginLeft: '4px' }}> Wild card</span>
                        </div>
                    }</Form.Label>
                    <Form.Control maxLength={props.maxLength} required={props.name === 'itemReferenceNumber' && searchList?.itemReferenceToggle} type={elementSchema.type} value={searchList[elementName as keyof SearchList]} onChange={(e: any) => setSearchList({ ...searchList, [elementName as keyof SearchList]: e.target.value })} />
                </Form.Group>
            )
        }
    }

    const handleClearTerm = (key: any) => {

        setSearchList((prevTerms) => {
            const updatedTerms = { ...prevTerms } as any;
            const keysToDelete = {
                createdAt: ['startDate', 'endDate', 'startTime', 'endTime'],
                orderIdentifier: ['orderIdentifier'],
                itemReferenceNumber: ['itemReferenceNumber'],
                amount: ['subTotalStartAmount', 'subTotalEndAmount'],
                totalAmount: ['startAmount', 'endAmount'],
                paymentType: ['paymentTypeSelect', 'paymentType'],
                lastFourOnCard: ['lastFourOnCard'],
                initiatedBy: ['initiatedBy'],
                nameOnCard: ['nameOnCard'],
                transactionType: ['customSelectComponent'],
                departmentId: ['departmentIds']
            } as any;

            (keysToDelete[key] || [key]).forEach((k: any) => {
                updatedTerms[k] = k === 'startDate' || k === 'endDate' ? '' : undefined;
            });
            return updatedTerms;
        });

        setBadges((prevTerms: any) => {
            const updatedTerms = { ...prevTerms };
            updatedTerms[key] = undefined;
            return updatedTerms;
        });
    };

    const SearchedTerms = ({ terms, onClearTerm }: any) => {
        const departmentNamesMap = Object.fromEntries(
            (currentUser?.userClients.flatMap(uc => {
                const client = clients?.find(c => c.msbId === uc.clientMSBId) as any;
                return (client?.departments || [])
                    .map((d: any) => d ? [d.msbId, `${d.name}`] : undefined)
                    .filter((entry: any): entry is [string, string] => entry !== undefined);
            }) ?? []) as [string, string][]
        );

        const termDisplayMap = {
            createdAt: {
                label: `Transaction date time (${moment.tz(timeZone)?.zoneAbbr?.() || 'Unknown'})`,
                transform: (v: string) => v.split('<->')
                    .map(date => moment.utc(date).tz(timeZone).format('LL, h:mm A'))
                    .join(' - ')
            },
            orderIdentifier: { label: 'Transaction ID' },
            itemReferenceNumber: { label: 'Item reference' },
            itemReferenceToggle: {
                label: 'Item reference wild card',
                transform: (v: boolean) => v ? 'Enabled' : 'Disabled'
            },
            totalAmount: {
                label: 'Total amount ranges',
                transform: (v: string) =>
                    v.split('<->').map((code) => CurrencyAmountFormatter.format(Number(code)) || `Unknown (${code})`).join(' - ')
            },
            amount: {
                label: 'Subtotal amount ranges',
                transform: (v: string) =>
                    v.split('<->').map((code) => CurrencyAmountFormatter.format(Number(code)) || `Unknown (${code})`).join(' - ')
            },
            paymentType: {
                label: 'Payment type',
                transform: (v: string) => v.replace(/\|/g, ', ')
            },
            lastFourOnCard: { label: 'Last 4 of credit card or account number' },
            initiatedBy: { label: 'Initiated by' },
            nameOnCard: { label: 'Customer name' },
            transactionType: {
                label: 'Transaction type',
                transform: (v: string) =>
                    v.split('|').map((code) => TransactionTypeEnum[Number(code)] || `Unknown (${code})`).join(', ')
            },
            departmentId: {
                label: 'Selected departments',
                transform: (ids: any) => ids.split('|').map((id: any) => departmentNamesMap[id] || id).join(', ')
            }
        } as any;

        return (
            <div className="badge-tab">
                {Object.entries(terms)
                    .filter(([_, value]) => value)
                    .map(([key, value], index) => {
                        const { label, transform } = termDisplayMap[key] || { label: key };
                        if (key != 'departmentId' && key != 'paymentChannelId')
                            return (
                                <Badge key={index} pill className="badge-pill">
                                    <span><strong>{label} :</strong> {transform ? transform(value) : value}</span>
                                    {/* <Button variant="link" size="sm" onClick={() => onClearTerm(key)} className="badge-remove">&times;</Button> */}
                                </Badge>
                            );
                    })}
            </div>
        );
    };

    const isDateRequired = () => {
        const isNotRequired = searchList?.itemReferenceNumber || searchList?.startAmount || searchList?.endAmount || searchList?.nameOnCard || searchList?.lastFourOnCard || searchList?.initiatedBy
        return !isNotRequired
    }

    return (
        <>
            <Form className="table-search advanced-search" onSubmit={handleSubmit}>
                <Row>
                    {reportType === ReportType.ClientBanking ?
                        <>
                            <Col>
                                <Form.Group controlId="client">
                                    <Form.Label>Client</Form.Label>
                                    <Form.Control as="select" type="input" required value={searchList.clientIds} onChange={(e: any) => setSearchList({ ...searchList, clientIds: e.target.value })}>
                                        <option key={"client_default"} value="" selected disabled hidden>Select Client</option>
                                        {clientList?.length > 1 &&
                                            clientList?.map((client: any, index: any) => (
                                                <option key={`client_${client.msbId}`} value={client.msbId}>{client.businessName}</option>
                                            ))
                                        }
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                        </> :
                        <>
                            <Col xl={4} lg={6} md={6} sm={12}>
                                <Form.Group controlId="startDate">
                                    <Form.Label>Start date</Form.Label>
                                    <Form.Control type="date" name="startDate" required={true} max={moment(defaultEndDate)?.format('YYYY-MM-DD')} value={searchList.startDate} onChange={(e) => setSearchList({ ...searchList, startDate: e.target.value })} />
                                </Form.Group>
                            </Col>
                            <Col xl={4} lg={6} md={6} sm={12}>
                                <Form.Group controlId="endDate">
                                    <Form.Label>End date</Form.Label>
                                    <Form.Control type="date" name="endDate" required={true} min={moment(searchList.startDate)?.format('YYYY-MM-DD')} max={moment(defaultEndDate)?.format('YYYY-MM-DD')} value={searchList.endDate} onChange={(e) => setSearchList({ ...searchList, endDate: e.target.value })} />
                                </Form.Group>
                            </Col>
                        </>
                    }
                    <Col xl={4} lg={12} md={12} sm={12}>
                        <ButtonToolbar>
                            <Button variant="link" onClick={() => setShowModal(true)} disabled={isFetching}>Advanced Search</Button>
                            <Button variant="outline-secondary" onClick={clearForms} disabled={isFetching}>Reset</Button>
                            <Button type="submit" disabled={isFetching}>Search</Button>
                        </ButtonToolbar>
                    </Col>
                    { badges &&
                        <PerfectScrollbar>
                            <div style={{ marginTop: '12px' }} >
                                <span style={{ fontWeight: 700 }}>Search Terms: </span>
                                <p style={{ color: '#8c8c8c' }}>All terms displayed, excluding Department and Payment Channels</p>
                            </div>
                            <div className="badge-container">
                                <SearchedTerms terms={badges} onClearTerm={handleClearTerm} />
                            </div>
                        </PerfectScrollbar>
                    }
                </Row>
            </Form>

            <Modal show={showModal} onHide={onHideModal} size="lg" className="advanced-search-modal">
                <Modal.Header closeButton />
                <Modal.Body>
                    <FormHeader title="Advanced Search" description="Please enter the details below to narrow your search results" />
                    <Form className="advanced-search-form" onSubmit={handleSubmit}>
                        {reportType != ReportType.ClientBanking &&
                            <Row>
                                <Col>
                                    <Form.Group controlId="startDate">
                                        <Form.Label>Start date</Form.Label>
                                        <Form.Control type="date" name="startDate" required={true} max={moment(defaultEndDate)?.format('YYYY-MM-DD')} value={searchList.startDate} onChange={(e) => setSearchList({ ...searchList, startDate: e.target.value })} />
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group controlId="endDate">
                                        <Form.Label>End date</Form.Label>
                                        <Form.Control type="date" name="endDate" required={true} min={moment(searchList.startDate)?.format('YYYY-MM-DD')} max={moment(defaultEndDate)?.format('YYYY-MM-DD')} value={searchList.endDate} onChange={(e) => setSearchList({ ...searchList, endDate: e.target.value })} />
                                    </Form.Group>
                                </Col>
                            </Row>}
                        <Row>
                            {formSchema && Object.keys(formSchema).map((key, ind) => (
                                <div className="col-sm-6" key={key}>
                                    {getFormElement(key, formSchema[key])}
                                </div>
                            ))}
                        </Row>
                        {clients && <DepartmentPaymentChannelSelector reportType={reportType} currentUser={currentUser} paymentChannelsByUser={paymentChannelsByUser} clientNames={clientNames} onSelectionChange={updateSelection} />}
                        <div className="advanced-search-footer">
                            <Button type="submit" data-testid="modal-search-button">
                                Search
                            </Button>
                            <Button form='navientUserSubmit' className="navientUserFooterCancel" variant="link" onClick={() => setShowModal(false)}>
                                Cancel
                            </Button>
                        </div>
                    </Form>
                </Modal.Body>
            </Modal>
        </>
    );
};



const mapStateToProps = (state: IAppState) => {
    return {
        clients: state.clients.currentPage?.data,
        searchFields: state.paymentTransactions.searchFields,
        paymentChannelsByUser: state.paymentTransactions.paymentChannelsByUser,
        currentUser: state.auth.currentUser,
        clientNames: state.clients.clientNames,
        departmentNames: state.clients.departmentNames,
        actionResult: state.clients.actionResult,

    };
};

export default connect(mapStateToProps)(AdvancedSearch);